@import "../../variables.scss";

.driveContent {
  min-height: 100vh;
}

.driveHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.dragBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;

  + .driveContent {
    opacity: 0.2;
  }
}

.dragBackgroundInner {
  border: 2px dashed $grey-cf;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragBackgroundContent {
  text-align: center;
}

.dragBackgroundContentText {
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.09px;
  color: $grey-3;
  margin-top: 15px;
}

.dragBackgroundContentSubText {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.07px;
  color: rgba(51, 51, 51, 0.7);
}

.containerNoFile {
  background: white;
  padding: 24px;
}

.containerNoFileInner {
  border: 2px dashed $grey-cf;
}

.containerSecuredMail {
  padding: 0;
  background: white;
}

.containerSecuredMailInner {
  padding: 20px;
  border: 2px dashed $grey-cf;
}

.containerSecureChatInner {
  padding: 10px;
  border: 2px dashed $grey-cf;

  .dragBackgroundContentText {
    margin-top: 5px;
  }
}

.containerSecuredMail {
  .react-tags__search {
    display: none;
  }
  .react-tags {
    cursor: default;

    button {
      cursor: pointer;
    }
  }
}

.secured-mail-container {
  .dragBackgroundContentText {
    font-size: 15px;
    margin-top: 10px;
  }
  
  .dragBackgroundContentSubText {
    font-size: 13px;
  }
  
  table { 
    width: '100%';

    tr {
      td:first-child {
        width: 100px;
        margin-right: 10px;
      }
      td {
        border-bottom: 10px solid transparent;
      }
    }
  }
  .attachmentItem {
    line-height: 20px; 
    display: inline-block;
    padding: 5px 10px;
    background: $grey-cf;
    border: 1px solid black;
  }
}
.secureChatMessageFilesContainer {
  .attachmentItem {
    line-height: 20px; 
    display: inline-block;
    padding: 5px 10px;
    background: #f1f1f1;
    border: 1px solid #d1d1d1;
    margin-right: 10px;
    cursor: pointer;
  }
}
.securemail-subject input {
  border: 1px solid $grey-cf;
  padding: 10px;

  &:focus {
    border: 1px solid $grey-cf;
  }
}
.securemail-wrapper {
  border: 1px solid $grey-cf;

  .securemail-editor {
    min-height: 120px;
    max-height: 180px;
    padding: 5px 10px;
  }
}
.securemail-wrapper-mobile {
  border: 1px solid $grey-cf;
  
  .securemail-editor-mobile {
    padding: 5px 10px;
  }
}
.ViewPermissionsTags {
  min-height: 100px;
}
.ViewPermissionsDialog.ms-Dialog-main {
  min-width: 600px;
}